<template>
  <exception-page type="403" />
</template>

<script>
import ExceptionPage from '~/exception/ExceptionPage'
export default {
  components: {ExceptionPage}
}
</script>

<style scoped>

</style>
